<template>
  <div>
    <Notify></Notify>
    <CardForm class="mt-6">
      <template slot="card-body">
        <div>
          <!-- here {{ form.type_id }} -->
          <Stepper
            :number="1"
            :data_stepper="form_stepper"
            @handel="handleSubmit"
          >
            <template #password>
              <v-col cols="6">
                <label class="text-xs text-typo font-weight-bolder ms-1">{{
                  $t("auth.Password")
                }}</label>
                <span class="text-danger astrik mr-2">*</span>

                <v-text-field
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  v-model="form.password"
                  :rules="rules.password"
                  lazy-validation
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="*********"
                  class="font-size-input placeholder-lighter mt-2 mb-4"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="text-xs text-typo font-weight-bolder ms-1">{{
                  $t("auth.Repeat Password")
                }}</label>
                <span class="text-danger astrik mr-2">*</span>

                <v-text-field
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  v-model="form.password_confirmation"
                  :rules="rules.password_confirmation"
                  lazy-validation
                  color="rgba(0,0,0,.6)"
                  light
                  placeholder="*********"
                  class="font-size-input placeholder-lighter mt-2 mb-4"
                >
                </v-text-field>
              </v-col>
            </template>
          </Stepper>
        </div>
      </template>
    </CardForm>
  </div>
</template>
<script>
import CardForm from "../Components/CardForm.vue";
import Input from "../Components/Input.vue";
import Notify from "../Components/Notify.vue";
import Stepper from "./Stepper.vue";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Wizard",
  components: {
    CardForm,
    Input,
    Notify,
    Stepper,
  },
  data() {
    return {
      card: {
        title: this.$i18n.t("CreateUser"),
        loading: true,
        sub_title: this.$i18n.t("user.user info"),
        // stepper: true,
      },
      show1: false,
      show2: false,
      form: {
        type_id: "",
        paswword: "",
        paswword_confirmation: "",
      },
      rules: {
        password: [
          (v) => !!v || this.$i18n.t("auth.Password is required"),
          (v) =>
            (v && v.length >= 6) ||
            this.$i18n.t("auth.minimum 6 characters at least"),
        ],
        password_confirmation: [
          (v) => !!v || this.$i18n.t("auth.Password Confirmation is required"),
          (v) =>
            v == this.form.password ||
            this.$i18n.t(
              "auth.Password and password confirmation does not match"
            ),
        ],
      },
      form_stepper: {
        header: [
          { name: this.$i18n.t("contractors.contractor info") },
          { name: this.$i18n.t("user.user info") },
        ],
        content: [
          {
            name: this.$i18n.t("contractors.contractor info"),
            form: [
              {
                value: "",
                label: this.$i18n.t("Company Name"),
                placeholder: "Company Name",
                value_text: "company_name",
                req: true,
                rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
              },
              {
                type: "number",
                value: "",
                label: this.$i18n.t("contractors.ibaw"),
                placeholder: "",
                value_text: "ibaw",
                req: true,
                rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
              },
              {
                value: "",
                label: this.$i18n.t("First Name"),
                placeholder: "micel",
                value_text: "first_name",
                req: true,
                rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
              },
              {
                value: "",
                label: this.$i18n.t("Last Name"),
                placeholder: "perior",
                value_text: "last_name",
                req: true,
                rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
              },
              {
                type: "number",
                value: "",
                label: this.$i18n.t("contractors.mobile"),
                placeholder: "+963 978 789 699",
                value_text: "mobile",
                req: true,
                rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
              },
              {
                value: "",
                label: this.$i18n.t("auth.Phone Number"),
                placeholder: "+963 978 789 699",
                value_text: "phone",
                req: true,
                rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
              },
              {
                type: "number",
                value: "",
                label: this.$i18n.t("contractors.kvk"),
                placeholder: "",
                value_text: "kvk",
                req: true,
                rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
              },
              {
                type: "number",
                value: "",
                label: this.$i18n.t("contractors.btw"),
                placeholder: "",
                value_text: "btw",
                req: true,
                rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
              },

              {
                col: "12",
                type: "textarea",
                label: this.$i18n.t("address"),
                error: null,
                value: "",
                rules: [(v) => !!v || this.$i18n.t("news is required")],
                label: this.$i18n.t("address"),
                placeholder: "",
                value_text: "address",
              },
            ],
          },
          {
            name: this.$i18n.t("user.user info"),
            form: [
              {
                col: "12",
                value: "",
                visible: true,
                type: "text",
                value_text: "email",
                label: this.$i18n.t("auth.Email Address"),
                placeholder: "test@exmaple.com",
                req: true,
                rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
              },
            ],
          },
        ],
      },
      toggle_exclusive: 2,
    };
  },

  computed: {
    ...mapState("form", ["formData"]),
  },
  methods: {
    ...mapMutations([
      "SET_COLLECTION",
      "SET_FUNCTION",
      "SET_CARD",
      "SET_CARD_LOADING",
    ]),
    ...mapMutations("form", ["SET_FORM_STYLE", "SET_LOADING_STEPPER"]),
    set_data() {
      this.SET_COLLECTION("user");
      this.SET_FUNCTION("add_user");
      // this.SET_FORM_STYLE(this.style_form);
      this.SET_CARD(this.card);
      this.SET_CARD_LOADING(false);
    },

    handleSubmit(value, valid) {
      this.error_msg = "";
      // console.log(this.files);
      this.SET_LOADING_STEPPER(true);
      if (valid) {
        const formData = new FormData();
        value.map(function (data) {
          data.form.map(function (item) {
            console.log(item.value_text, item.value);
            if (item.value != undefined && item.value != "") {
              if (
                (item.type == "select" && item.type_select == "multiple") ||
                (item.type == "autocomplete" && item.type_select == "multiple")
              ) {
                var value = item.value;
                for (var i = 0; i < value.length; i++) {
                  const val = value[i];
                  formData.append(`${item.value_text}[ ${i} ]`, val);
                }
              } else {
                formData.append(item.value_text, item.value);
              }
            }
          });
        });
        formData.append("password", this.form.password);
        formData.append(
          "password_confirmation",
          this.form.password_confirmation
        );
        console.log(...formData);
        axios
          .post("contractors/store", formData)
          .then((response) => {
            this.SET_LOADING_STEPPER(false);
            this.$router.push({
              name: "Contractors",
              params: {
                message: { msg: response.data.message, type: "Success" },
              },
            });
          })
          .catch((error) => {
            // alert(1);
            this.SET_LOADING_STEPPER(false);
            if (error && error.response.status == 422) {
              // this.errors = error.response.data.errors;
              this.error_msg = error.response.data.errors;
            } else {
              this.error_msg =
                (error.response && error.response.data.message) ||
                error.message ||
                error.toString();
            }
            this.$store.commit("form/SET_NOTIFY", {
              msg: this.error_msg,
              type: "Error",
            });
          });
      } else {
        this.SET_LOADING_STEPPER(false);
        this.$store.commit("form/SET_NOTIFY", {
          msg: this.$i18n.t("general.there is feild empty please checked"),
          type: "Error",
        });
      }

      //
    },
  },
  mounted() {
    this.set_data();
    document.title = this.$i18n.t("CreateContractor");
  },
};
</script>